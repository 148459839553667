import React from "react";

import { apiFetch } from "../../../../../../apiBindings";
import pushToAnalytics from "../../../../../../utils/analytics";
import StorageHandler from "../../../../../../utils/StorageHandler";
import Columns from "../../../../Columns";
import NewCheckbox from "../../../../forms/NewCheckbox";
import NewInput from "../../../../forms/NewInput";
import LoadingIndicator from "../../../../LoadingIndicator";
import Button from "../../../../NewForms/Button";
import NewHeadline from "../../../../NewHeadline";
import { UncontrolledNotification as Notification } from "../../../../Notification";
import useStore from "./store";

const storage = new StorageHandler();

const ContactForm = ({ name = "apprenticeship" }) => {
    const {
        formData,
        onChange,
        addFormError,
        clearError,
        hasErrorAt,
        setErrors,
        errors,
        sending,
        setSending,
        sendingError,
        setSendingError,
        emailSent,
        setEmailSent,
    } = useStore();

    const onSubmit = (event) => {
        event.preventDefault();
        // Check if the fields are valid
        const localErrors = [];
        Object.keys(formData).map((item) => {
            if (
                (formData[item] === "" && item !== "phone" && item !== "job") ||
                formData[item] === false
            ) {
                localErrors.push(item);
            }
        });

        if (localErrors.length > 0) {
            setErrors(localErrors);
            return;
        }
        if (errors.length === 0) {
            setSending(true);

            document
                .getElementById("apprenticeship-contact-form")
                .scrollIntoView({ behavior: "smooth" });

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            void apiFetch("/contact/apprenticeship", {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.success) {
                        storage.set(`${name}:contact-form:sent-at`, Date.now());
                        storage.set("app:uid", responseData.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: `${name}:contact-form`,
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };
    return (
        <div
            id="apprenticeship-contact-form"
            style={{
                marginTop: "-100px",
                paddingTop: "100px",
                marginBottom: "20px",
            }}
        >
            {sendingError ? (
                <Notification>
                    <NewHeadline>Entschuldigung</NewHeadline>
                    <div>
                        Es ist leider ein Fehler aufgetreten. Versuche es bitte
                        erneut.
                    </div>
                    <Button onClick={() => setSendingError(false)} fullWidth>
                        Zum Formular
                    </Button>
                </Notification>
            ) : sending ? (
                <Notification>
                    <LoadingIndicator>
                        Deine Nachricht wird übermittelt. Habe bitte einen
                        Moment Geduld.
                    </LoadingIndicator>
                </Notification>
            ) : emailSent ? (
                <Notification>
                    <NewHeadline>Vielen Dank für Deine Anfrage</NewHeadline>
                    <div>
                        Wir haben Deine Anfrage erhalten und melden uns in Kürze
                        bei Dir.
                    </div>
                </Notification>
            ) : (
                <form onSubmit={onSubmit}>
                    <NewHeadline>Deine Nachricht an uns:</NewHeadline>
                    <Columns>
                        <Columns.Column>
                            <NewInput
                                name="name"
                                value={formData.name}
                                onChange={onChange}
                                placeholder="Name"
                                validation="name"
                                required
                                onError={() => addFormError("name")}
                                onErrorSolved={() => clearError("name")}
                                forceError={hasErrorAt("name")}
                            />

                            <NewInput
                                name="email"
                                value={formData.email}
                                onChange={onChange}
                                placeholder="E-Mail"
                                validation="email"
                                required
                                onError={() => addFormError("email")}
                                onErrorSolved={() => clearError("email")}
                                forceError={hasErrorAt("email")}
                            />

                            <NewInput
                                name="phone"
                                value={formData.phone}
                                onChange={onChange}
                                placeholder="Telefon"
                                validation="phone"
                                onError={() => addFormError("phone")}
                                onErrorSolved={() => clearError("phone")}
                                forceError={hasErrorAt("phone")}
                            />

                            <NewInput
                                name="job"
                                value={formData.job}
                                onChange={onChange}
                                placeholder="Ausbildungplatz"
                                validation="job"
                                onError={() => addFormError("job")}
                                onErrorSolved={() => clearError("job")}
                                forceError={hasErrorAt("job")}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <NewInput
                                name="message"
                                type="textarea"
                                placeholder="Deine Nachricht"
                                value={formData.message}
                                onChange={onChange}
                                validation="message"
                                onError={() => addFormError("message")}
                                onErrorSolved={() => clearError("message")}
                                forceError={hasErrorAt("message")}
                                fullHeight
                            />
                        </Columns.Column>
                    </Columns>

                    <Columns>
                        <Columns.Column>
                            <NewCheckbox
                                required
                                onChange={onChange}
                                checked={formData.isPrivacyPolicyAccepted}
                                name="isPrivacyPolicyAccepted"
                                validation="isPrivacyPolicyAccepted"
                                onError={() =>
                                    addFormError("isPrivacyPolicyAccepted")
                                }
                                onErrorSolved={() =>
                                    clearError("isPrivacyPolicyAccepted")
                                }
                                forceError={hasErrorAt(
                                    "isPrivacyPolicyAccepted"
                                )}
                            >
                                <div>
                                    Ich akzeptiere die{" "}
                                    <a href="/datenschutz" target="_blank">
                                        Datenschutzerklärung
                                    </a>
                                </div>
                            </NewCheckbox>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                fullWidth
                                data-gtag={`${name}:contact-form`}
                            >
                                Nachricht senden
                            </Button>
                        </Columns.Column>
                    </Columns>
                </form>
            )}
        </div>
    );
};

export default ContactForm;
